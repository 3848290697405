// BLUMFELD SKIN <- JIRAFA SKIN
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;700&display=swap');

body {
  color: #2b2b2b;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

// NAV

.navbar-light {
  background: transparent !important;
  transition: background 0.2s;
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  body[data-scrolled] & {
    background-color: $white !important;
  }
}

.product-listing-page .navbar-brand {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.1s;
  transition-delay: 0;
}
body[data-scrolled] {
  .navbar-brand {
    opacity: 1;
    transition-delay: 0.2s;
    transform: translateY(0);
  }
}

#header .navbar {
  .main-menu {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
  }

  .navbar-brand {
    img {
      height: 2.5rem;
      max-height: 2.5rem;

      @include media-breakpoint-up(md) {
        height: 3.5rem;
        max-height: 3.5rem;
      }

      &:hover,
      &:focus {
        filter: opacity(0.66);
      }
    }
  }
}

body.product-detail.product-detail-46813,
body.product-detail.product-detail-46845 {
  #header {
    // background-color: $white !important;

    .navbar {
      // background-color: $white !important;
      // transition: none;
    }
  }
}

.navbar .hamburger {
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-500;
      font-style: italic;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: #fff, $alpha: 0.9) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki {
  .card-title {
    text-align: center;

    .sub-title {
      font-size: smaller;
    }
  }
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $gray-300;

  .nigiri-content {
    padding: 1.25rem 0;
    padding-top: 0;
    margin-top: 0.2rem;
    color: $primary;
    @include media-breakpoint-down(md) {
      display: table;
    }

    .badge.sold-out-status {
      transform: none;
      right: 0 !important;
      background-color: $primary;
      color: $white;
      z-index: 2;
      font-size: 0.8em;
    }

    .nigiri-date {
      font-size: $h3-font-size;
    }

    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      text-transform: uppercase;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
        padding-left: 0;
        line-height: 2rem;
      }
      p {
        margin-bottom: 0px;
      }

      .title {
        font-size: 0.6em;
        margin-bottom: 0;
        color: $primary;
      }

      .location {
        font-size: $h3-font-size !important;
        font-weight: 500;
        color: $primary;
      }

      .nigiri-tags {
        font-size: 1rem;
        .tag {
          margin-right: 10px;
        }
      }
    }

    // .nigiri-cta .btn {
    //   color: $white;
    //   background: $success;
    //}
  }
  // .subtitle {
  //   font-size: 0.5em;
  //   margin-bottom: 0;
  //   color: $primary;
  //   font-weight: normal;
  //   @include media-breakpoint-down(md) {
  //     line-height: 1rem;
  //     margin-bottom: 0.5rem;
  //   }
  // }
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
  }
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    tbody {
      background-color: $black !important;
    }

    colgroup {
      display: none;
    }

    div.visible.spinner-border,
    div.invisible.spinner-border {
      display: none !important;
      // visibility: hidden !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// OTHER

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.blurry-background-image:before {
  background-image: none;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $body-bg;
  color: $primary;
  border-bottom: 10px solid $primary;
}

#product-listing,
#ticket-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}
